import request from '../../utils/request'
// 联系我们
export function getmessge(data = {}) {
  return request({
    url: `/website/message/list`,
    method: 'post',
    params: {
      limit: data.pageSize,
      page: data.pageNum,
      name: data.name
    }
  })
}
// 删除
export function handlDel(data = []) {
  return request({
    url: '/website/message/delete',
    method: 'DELETE',
    data
  })
}
