<template>
  <div class="home">
    <div class="table">
      <div class="header">
        <el-input
          v-model="query.name"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width:174px"
          size="mini"
        />
        <el-button type="primary" size="mini" @click="gettabledata">查询</el-button>
        <div style="float:right;">
          <el-button type="danger" size="mini" @click="BatchhandleDelet()">批量删除</el-button>
        </div>
      </div>
      <div>
        <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="姓名" width="180" />
          <el-table-column prop="contact" label="手机号" width="180" />
          <el-table-column prop="others" label="备注" />
          <el-table-column prop="requirements" label="状态" />
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDelet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="block">
          <el-pagination
            :current-page="query.pageNum"
            :page-sizes="[ 5 ,10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            size="small"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmessge, handlDel } from '../../api/admin/messge'
export default {
  data() {
    return {
      tableData: [],
      query: {
        pageNum: '1',
        pageSize: '20',
        name: ''
      },
      total: 0,
      ids: []
    }
  },
  created() {
    this.gettabledata()
  },
  methods: {
    gettabledata() {
      getmessge(this.query)
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.items
          this.total = res.data.total
        })
    },
    // 删除
    handleDelet(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel([row.id])
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.gettabledata()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 分页
    handleSizeChange(e) {
      console.log('handleSizeChange', e)
      this.query.pageSize = e
      this.gettabledata()
    },
    handleCurrentChange(e) {
      console.log('handleCurrentChange', e)
      this.query.pageNum = e
      this.gettabledata()
    },
    // 批量删除
    BatchhandleDelet() {
      if (this.ids.length <= 0) {
        this.$notify({
          title: '警告',
          message: '请选择要删除的行',
          duration: 1500,
          type: 'warning'
        })
        return
      }
      this.newsLoading = true
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel(this.ids)
            .then(res => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.gettabledata()
            })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 选取某一行数据
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val)
      this.ids = val.map(o => o.id)
      console.log('ids', this.ids)
    }
  }

}
</script>

<style lang="scss">
.table {
  border: 1px solid #ddd;
  .header{
    border-bottom: 1px solid #ddd;
    padding:10px;
    .el-button{
      margin-left: 10px;
    }
  }
}
/* .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
} */
.el-pagination {
  margin:10px;
  text-align: center;
}
</style>
